<template>
  <div>
    <i class="bi bi-shield-x"></i>
    <p>{{$t('权限不足')}}</p>
  </div>
</template>

<script>
export default {
  name: 'RoleLack',
  data () {
    return {}
  }
}
</script>

<style scoped>
  div{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  i{
    font-size: 100px;
    color: #c6c6c6;
  }
  p{
    font-size: 40px;
    color: #c6c6c6;
    user-select: none;
  }
</style>
